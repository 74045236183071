import React from 'react';
import '../ebook.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

function earthMinedDiamonds(){
	return(
		<Row>
		<Col xs={12} className="earthMinedDiamondsTitle">
		<h3 className="ebookMobileTitle">WHITE SERIES</h3>
		<h4 className="ebookMobileSubTitle">EARTH MINED DIAMONDS</h4>
		</Col>
		<Col xs={12} className="ebookMobilePaddingZero">
		<video src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/08/Sequence-01.mp4" width="100%" type="video/mp4" controls autoPlay className="earthMinedVideo"/>
		</Col>
		<Col xs={12}>
		<p>The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class. The White Series is the 2nd generation of Super Ideal Cuts that is perfected to outperform any diamonds in all lighting conditions.
		</p>
		<p>Our Quality (Natural Earth Mined Diamonds)</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> AGS or GIA Certified only</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> All in strict Ideal Proportions (Super Ideal Cuts HCA score of 1.4 or below only)</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> All in highest light performance (backed by ASET scope)</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> Perfect Optical symmetry (backed by Hearts & Arrows scope)</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> No dugged out girdle</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> No Brownish, grayish or off color hues</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> No Fluorescence</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> We avoid certain flat carat weights (diamond pricing brackets)</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> All inclusions are 100% eye clean</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> No black inclusions</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> No risky or durability issues clarity characteristics (Eg. Knot, Chip, Cavity, Bruise, Indented Natural, Natural, Laser Grill Hole, Etch Channel, Internal Graining, Surface Graining, Feather on Girdles, Heavy Clouding (milky) or inclusion on surface.</p>
		<p className="tickMarkList"><Image className="emdTicks" src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/12/tick-mark.jpg" alt="" width="34" height="33" /> Conflict Free (Ethically and responsibly sourced)</p>
		</Col>
		</Row>
		);
}

export default earthMinedDiamonds;