import React from 'react';
import '../ebook.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

function decagonWhiteSeries(){
	return(
		<Row>
		<Col md={12} className="decagonWhiteSeriesTitle">
		<h3 className="ebookMobileTitle">DECAGON</h3>
		<h4 className="ebookMobileSubTitle">WHITE SERIES™</h4>
		</Col>
		<Col md={12} className="ebookMobilePaddingZero">
		<Image src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2023/01/decagon-diamond.jpg"  className="decagonWhiteSeriesImage"/>
		</Col>
		<Col md={12} className="ebookMobilePaddingZero">
		<p>(SAMPLE PARA) The White Series Diamond collection are patented cut diamonds exclusively available in JANNPAUL. They are engineered and designed to achieve an
		extreme brilliance, fire and scintillation, with the highest light performance in its own facet shape class.</p>
		</Col>
		</Row>
		);
}

export default decagonWhiteSeries;