import { useState } from 'react';
import EbookMobileYourDiamondJourney from './ebook-mobile-selected-menus/mobile-your-diamond-journey';
import EbookMobileCutAnalysis from './ebook-mobile-selected-menus/mobile-cut-analysis';
import EbookMobileLabReport from './ebook-mobile-selected-menus/mobile-lab-report';
import EbookEarthMinedDiamonds from './ebook-right-template/earth-mined-diamonds';
import EbookEthicalSourcing from './ebook-right-template/ethical-sourcing';
import EbookDecagonWhiteSeries from './ebook-right-template/decagon-white-series';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from 'axios';
import './ebook-mobile.css';
import Spinner from 'react-bootstrap/Spinner';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EbookMobile({ setShowAlert, productData, certificateNumber, setCertificateNumberAvailable }) {
  const [mobileSelectedMenu, setMobileSelectedMenu] = useState({
    menu: 'EbookMobileLabReport',
  });
  const [mobileProductData, setMobileProductData] = useState(productData);
  const [certificateNumberMobile, setCertificateNumberMobile] = useState(certificateNumber);
  const [isLoading, setIsLoading] = useState(false);
  const validateCertificateNumberAndUpdateData = async certificateNumberHeader => {
    setIsLoading(true);
    let isValidCertificateNumber = false;
    const apiURL =
      'https://jannpaulvc.learnedstudio.com/wp-content/plugins/les-product-management/public/factory-jannpaul-sync-handler.php?reactHandler=getEbookDataForReact';
    const responseProductData = await axios.get(apiURL + '&certificateNumber=' + certificateNumberHeader);
    if (responseProductData.data === 'Not Available') {
      isValidCertificateNumber = false;
    } else {
      isValidCertificateNumber = true;
    }
    if (isValidCertificateNumber && certificateNumberHeader !== '') {
      setCertificateNumberAvailable(true);
    } else {
      setCertificateNumberAvailable(false);
      setShowAlert(true);
    }
    setCertificateNumberMobile(certificateNumberHeader);
    setMobileProductData(responseProductData.data);
    setIsLoading(false);
  };
  function EbookMobileTopMenu() {
    // menuList for displaying
    const menuList = [
      'Lab Report',
      'Your Diamond Journey',
      'Cut Analysis',
      'What Is Decagon White Series<sup>TM</sup>?',
      'About Earth Mined Diamonds',
      'Ethical Sourcing',
    ];
    // inserting eventkeys according to component names
    const mobileMenuComponents = [
      'EbookMobileLabReport',
      'EbookMobileYourDiamondJourney',
      'EbookMobileCutAnalysis',
      'EbookDecagonWhiteSeries',
      'EbookEarthMinedDiamonds',
      'EbookEthicalSourcing',
    ];
    // handling which menu is selected
    function handleMobileMenuClick(selectedMenu) {
      setMobileSelectedMenu({ menu: selectedMenu });
    }
    return (
      <Navbar key={'menu'} expand={false} collapseOnSelect>
        <Navbar.Toggle />
        <Navbar.Offcanvas placement="top">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Nav onSelect={handleMobileMenuClick}>
              {menuList.map((menuItem, index) => (
                <Nav.Link key={index} eventKey={mobileMenuComponents[index]}>
                  {parse(menuItem)}
                </Nav.Link>
              ))}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    );
  }

  function EbookMobileHeader() {
    const [certificateNumberHeader, setCertificateNumberHeader] = useState('');
    const displayEnteredCertificateNumber = e => {
      e.preventDefault();
      setCertificateNumberHeader(e.target.value);
    };
    return (
      <>
        <Row className="EbookHeaderMobile">
          <Col xs={1}>
            {/* Sandwich menu */}
            <EbookMobileTopMenu />
          </Col>
          <Col xs={11}>
            <Image
              className="EbookMobilelogo"
              src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={5} className="HeaderborderColumn">
            <p className="Leftheaderborder"></p>
          </Col>
          <Col xs={2} className="EbookHeaderIcon">
            <Image src="https://jannpaulvc.learnedstudio.com/wp-content/uploads/2022/04/MicrosoftTeams-image-49.png" />
          </Col>
          <Col xs={5} className="HeaderborderColumn">
            <p className="Rightheaderborder"></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              className="InputSearchBox"
              type="text"
              placeholder="Search Report No."
              required
              onChange={displayEnteredCertificateNumber}
              value={certificateNumberHeader}
              onKeyDown={e =>
                e.key === 'Enter' ? validateCertificateNumberAndUpdateData(certificateNumberHeader) : ''
              }
              style={isLoading ? { width: '96%' } : {}}
            />
            {isLoading ? <Spinner size="sm" /> : ''}
            {/* <span onClick={()=>setCertificateNumber("")}>×</span>
        <Button onClick={()=>console.log("Submit")}>
        Search
        </Button> */}
          </Col>
        </Row>
      </>
    );
  }

  function EbookProductDetails() {
    // Accordians for product details
    return (
      <Row>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header as="h4">Diamond Report Summary</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <p>Report Number</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.certificateNo}</Col>
            </Row>
            <Row>
              <Col>
                <p>Report Date</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.reportDate}</Col>
            </Row>
            <Row>
              <Col>
                <p>Description</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.description}</Col>
            </Row>
            <Row>
              <Col>
                <p>Shape and Cut diamonds</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.shape}</Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header as="h4">Grading Results</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <p>Carat Weight</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.carat} Carat</Col>
            </Row>
            <Row>
              <Col>
                <p>Color Grade</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.color}</Col>
            </Row>
            <Row>
              <Col>
                <p>Clarity Grade</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.clarity}</Col>
            </Row>
            <Row>
              <Col>
                <p>Cut Grade</p>
              </Col>
              <Col>: {mobileProductData.sidebarData.cut}</Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header as="h4">Additional Grading Information </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <p>Polish</p>
              </Col>
              <Col>{mobileProductData.sidebarData.polish}</Col>
            </Row>
            <Row>
              <Col>
                <p>Symmetry</p>
              </Col>
              <Col>{mobileProductData.sidebarData.symmetry}</Col>
            </Row>
            <Row>
              <Col>
                <p>Measurements</p>
              </Col>
              <Col>{mobileProductData.sidebarData.measurements}mm</Col>
            </Row>
            <Row>
              <Col>
                <p>Table Size</p>
              </Col>
              <Col>{mobileProductData.sidebarData.table}%</Col>
            </Row>
            <Row>
              <Col>
                <p>Crown Height</p>
              </Col>
              <Col>{mobileProductData.sidebarData.crownHeight}%</Col>
            </Row>
            <Row>
              <Col>
                <p>Pavilion Depth</p>
              </Col>
              <Col>{mobileProductData.sidebarData.pavillionHeight}%</Col>
            </Row>
            <Row>
              <Col>
                <p>Gridle Thickness</p>
              </Col>
              <Col>{mobileProductData.sidebarData.girdle}</Col>
            </Row>
            <Row>
              <Col>
                <p>Culet</p>
              </Col>
              <Col>{mobileProductData.sidebarData.culet}</Col>
            </Row>
            <Row>
              <Col>
                <p>Total Depth</p>
              </Col>
              <Col>{mobileProductData.sidebarData.depth}%</Col>
            </Row>
            <Row>
              <Col>
                <p>Fluorescence</p>
              </Col>
              <Col>{mobileProductData.sidebarData.fluorescence}</Col>
            </Row>
            <Row>
              <Col>
                <p>Comments</p>
              </Col>
              <Col>
                {mobileProductData.sidebarData.comments !== ''
                  ? mobileProductData.sidebarData.comments
                  : 'No comments'}
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </Row>
    );
  }

  function EbookMobileSelectedMenu() {
    // setting the component as per which menu is selected
    function handleMenu() {
      switch (mobileSelectedMenu.menu) {
        case 'EbookMobileLabReport':
          return (
            <EbookMobileLabReport
              productData={mobileProductData}
              certificateNumber={certificateNumberMobile}
            />
          );
        case 'EbookMobileYourDiamondJourney':
          return (
            <EbookMobileYourDiamondJourney
              productData={mobileProductData}
              certificateNumber={certificateNumberMobile}
            />
          );
        case 'EbookMobileCutAnalysis':
          return (
            <EbookMobileCutAnalysis
              productData={mobileProductData}
              certificateNumber={certificateNumberMobile}
            />
          );
        case 'EbookDecagonWhiteSeries':
          return <EbookDecagonWhiteSeries />;
        case 'EbookEarthMinedDiamonds':
          return <EbookEarthMinedDiamonds />;
        default:
          return <EbookEthicalSourcing />;
      }
    }
    return handleMenu();
  }

  return (
    <>
      <Container fluid>
        <EbookMobileHeader />
        <EbookProductDetails />
        <EbookMobileSelectedMenu />
      </Container>
    </>
  );
}

export default EbookMobile;
